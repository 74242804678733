import React, { Component } from "react"

import { OutboundLink } from "gatsby-plugin-google-gtag"
export default class RegionOverview extends Component {

  render() {

    return (
      <div>
        <section id="region-page" className="page-pad">
          <div className="page">
            <div className="column-side">
              <div id="region-deadline" className="box special">
                {this.props.interest === 0 ? <h4>Art Deadline</h4> : this.props.interest === 1 ?  <h4>Writing Deadline</h4> : <h4>Region Deadline</h4> }
                {new Date(
                  `${this.props.data.submissionDeadlineDate}`
                ).toDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
                <div className="detail">
                  {new Date(
                    `${this.props.data.submissionDeadlineDate}`
                  ).toLocaleTimeString('en-US', {timeZoneName: 'short'})} 
                </div>
              </div>
            </div>
            <div className="column-main">
              <h3>Guidelines</h3>
              <span
                dangerouslySetInnerHTML={{
                  __html: this.props.data.guidelines,
                }}
              />
              <h3>Announcements</h3>
              <span
                dangerouslySetInnerHTML={{
                  __html: this.props.data.announcements,
                }}
              />
              <h3>Events</h3>
              <span
                dangerouslySetInnerHTML={{ __html: this.props.data.events }}
              />
            </div>
            <div className="column-side">
              <div className="box-group">
                <div id="region-partner-info" className="box">
                  <div
                    id="region-partner-logo"
                    style={{
                      backgroundImage: "url(" + this.props.data.logoKey + ")",
                    }}
                  ></div>

                  <div id="region-partner-address">
                    {this.props.data.partnerName}
                    <br />
                    Attn:&nbsp;
                    {this.props.data.contactName
                      ? this.props.data.contactName
                      : null}
                    <br />
                    {this.props.data.contactPhone
                      ? this.props.data.contactPhone
                      : null}
                    <br />
                  </div>
                  <div id="region-partner-contact">

                    {this.props.data.contactEmail ? (
                      <a
                        href={`mailto:${this.props.data.contactEmail}?subject=Art%20%26%20Writing%20Awards`}
                        className="button"
                      >
                        Send Message
                      </a>
                    ) : null}
                  </div>
                </div>

                {/* Dsabled till ready */}
                {/* <div id="region-downloads" className="box">
                <h4>Downloads</h4>
                  {this.props.data.downloads
                    ? this.props.data.downloads.map((download, index) => {
                        return (
                          <li key={index}>
                            <OutboundLink href={download.fileUrl} rel="noreferrer" target="_blank">
                              {download.name}
                            </OutboundLink>
                          </li>
                        )
                      })
                    : null}
                  </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* <div id="share-button">
          <a
            href="#"
            className="button"
            data-share-email="mailto:?subject=TITLE&amp;body=URL"
            data-share-facebook="http://www.facebook.com/share.php?u=URL"
            data-share-twitter="https://twitter.com/intent/tweet?text=TITLE&amp;url=URL"
          >
            Share This Page
          </a>
        </div> */}
      </div>
    )
  }
}