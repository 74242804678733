import React, { Component } from "react"

import { Router, Link } from "@reach/router"
import Seo from "../seo"

import Overview from "./region-overview"
import About from "./region-about"

import Loading from "../loading-spinner"


import StaticBG from "../../assets/images/uploads/partner-vermont-hero.jpg"

export default class Region extends Component {
  state = {
    region: {},
    zip: "",
    interest: "",
    heroStatus: true
  }

  componentDidMount() {

    if (this.props.regions.length === 0) {
      console.log("🚀 ~ no region in props", this.props)
      if (!!localStorage.getItem("code")) {
 
        localStorage.getItem("code") === this.props.id
          ? this.getSingleRegion(localStorage.getItem("code"))
              .then(data => {
                this.setState(
                  {
                    region: data,
                    zip: localStorage.getItem("zip"),
                    interest: parseInt(localStorage.getItem("interest")),
                    heroStatus: false,
                  },
                  () => {}
                )
              })
              .catch(error => {
                console.log("error", error)
              })
          : this.getSingleRegion(this.props.id)
              .then(data => {
                this.setState({ region: data, heroStatus: false }, () => {})
              })
              .catch(error => {
                console.log("error", error)
              })

      } else {
      this.getSingleRegion(this.props.id)
        .then(data => {
          this.setState({ region: data, heroStatus: false }, () => {})
        })
        .catch(error => {
          console.log("error", error)
        })
      }
    } // experiment!
    // else if(this.props.id === undefined) {
    //   console.log(`debugging hard!`)
    // }
    
    else if (
      this.props.id !== this.props.regions[this.props.interest].code
    ) {

      console.log("🚀 ~ file: single-region.js:67 componentDidMount esle IF PROPS", this.props)

      console.log(`this.props.regions[this.props.interest].code === ${this.props.regions[this.props.interest].code}`)

      localStorage.setItem('selectedShortCode', this.props.regions[this.props.interest].code)

      this.setState(
        {
          region: this.props.regions[this.props.interest],
          zip: this.props.zip,
          interest: this.props.interest,
          heroStatus: false
        }
      )

      console.log('current state in props.id != props.regions[interest].code', this.state)

      // this.getSingleRegion(this.props.regions[this.props.interest].code)

      // // // this.getSingleRegion(this.props.id)
  
      //   .then(data => {
      //     console.log("🚀 ~ file: single-region.js:69 ~ Region ~ componentDidMount ~ data:", data)

      //     this.setState({ region: data, heroStatus: false }, () => {})
      //   })
      //   .catch(error => {
      //     console.log("error", error)
      //   })
    } else {
      console.log('from region finder')
      this.setState({
        region: this.props.regions[this.props.interest],
        zip: this.props.zip,
        interest: this.props.interest, 
        heroStatus: false
      })
    }
  }

  componentDidUpdate(prevProps) {


    if (prevProps.interest !== this.props.interest) {
      this.setState({ region: this.props.regions[this.props.interest], interest: this.props.interest })
    }
    if (this.props.id === localStorage.getItem("code") && this.state.zip === "") {
      this.setState({region: this.props.regions[this.props.interest], interest: this.props.interest, zip: this.props.zip })
    }

  }

  async getSingleRegion(code) {
    let response = await fetch(process.env.GATSBY_PORTAL_REGION + "/" + code)
    let data = await response.json()
    return data
  }

  // async getSingleRegionSingleUpdate(id) {
  //   let response = await fetch(process.env.GATSBY_PORTAL_REGION + "/Update/" + id)
  //   let data = await response.json()
  //   return data
  // }



  render() {
    console.log(`🚀 ~ file: single-region.js:117 ~ props, ${this.props.regions}`)
    this.state.heroStatus ? console.log("heroStatus ", this.state.heroStatus , ) : console.log("heroStatus ", this.state.heroStatus ,StaticBG, this.state.region )
    if (
      this.state.heroStatus 
    ) {
      return (
        <Loading />
      )
    } else {
    return (
      <div id="region">
               {this.state.region.name ? <Seo
          title={this.state.region.name}
          description={this.state.region.name + "is managed by the" + this.state.region.partnerName}
        /> : null}
        <nav
          id="nav-section"
          aria-expanded={this.props.navSection === true ? "true" : "false"}
          style={this.props.navSection === true ? { display: "block" } : null}
        >
          <ul>
            <li>
              <Link to="./">Overview</Link>
            </li>
            <li>
              <Link to="about">About this Region</Link>
            </li>

            <li>
              <Link to="../">Change my Region</Link>
            </li>
          </ul>
        </nav>
        <section id="region-hero" className="partner-header">

                      <div
              className="image"
              style={this.state.region.bannerKey ? {  backgroundImage: "url(" + this.state.region.bannerKey + ")", }: {backgroundImage: "url(" + StaticBG + ")"} }
            ></div>

          <div className="overlay">
            <h2>{this.state.region.name}</h2>
            {this.state.zip ? (
              <div className="zip">Serving Zip Code {this.state.zip}</div>
            ) : (
              <div className="zip"></div>
            )}
            <div className="switch">
              {this.state.zip ? (
                this.state.interest === 0 ? (
                  <a onClick={this.props.switchRegion} className="button">
                    Switch to Writing
                  </a>
                ) : this.state.interest === 1 ? (
                  <a onClick={this.props.switchRegion} className="button">
                    Switch to Art
                  </a>
                ) : null
              ) : (
                <a onClick={this.props.findRegion} className="button">
                  Find a New Region
                </a>
              )}
            </div>
          </div>
        </section>

        <Router>
          <Overview path="/" data={this.state.region} interest={this.state.interest} />
          <About
            path="/about"
            data={this.state.region}
            interest={this.props.interest}
            zip={this.props.zip}
          />
        </Router>
      </div>
    )}
  }
    
}