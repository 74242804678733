import React, { Component } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import RegionsAll from "../components/regions/all-regions"
import RegionSingle from "../components/regions/single-region"

import About from "../components/regions/region-about"
// import Updates from "../components/regions/region-updates"
// import UpdateSingle from "../components/regions/region-update"

import { Router, navigate } from "@reach/router"

const workType = ["art", "writing"]
const dateCreated = new Date(Date.now())

export class RegionsContainer extends Component {
  state = {
    country: "USA",
    zip: "",
    noZip: false,
    interest: 0,
    regions: [],
    navSection: false,
  }


  toggleSection = () => {
    this.setState({navSection: !this.state.navSection})
  };

  async queueAsyncFns(fns) {
    const values = []
    await fns.reduce((previous, current, index, array) => {
      const thenable = index === 1 ? previous() : previous
      return thenable.then(value => {
        values.push(value)
        return index === array.length - 1
          ? current().then(value => values.push(value))
          : current()
      })
    })

    return values
  }

  getRegions = async (country, zip) =>
    await fetch(
      `${process.env.GATSBY_PORTAL_REGION}/zip/${zip}?country=${country}`
    )
      .then(res => res.json())
      .catch(error => console.log("error", error))

  getSingleRegion = async code =>
    await fetch(process.env.GATSBY_PORTAL_REGION + "/" + code)
      .then(res => res.json())
      .catch(error => console.log("error", error))

  getBothRegions(country, zip, interest) {
    this.queueAsyncFns(
      workType.map((val, index) => () => this.getRegions(country, zip))
    ).then(codes => {
    console.log("🚀 ~ file: regions.js:61 ~ RegionsContainer ~ getBothRegions ~ codes:", codes)

      localStorage.setItem("zip", zip)
      localStorage.setItem("regionShortCode", codes[interest].regionShortCode)
      this.queueAsyncFns(
        codes.map(code => () => this.getSingleRegion(code.regionShortCode))
      ).then(bothRegions => {
       
        !bothRegions[interest].regionShortCode
          ? this.setState({ noZip: true })
          : this.setState({ regions: bothRegions }, () => {
          
              navigate(
                `/regions/${bothRegions[interest].regionShortCode}`
              )
            })
      })
    })
  }

  handleCountryChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      localStorage.setItem("country", e.target.value)
    })
  }

  handleZipChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () =>{
      localStorage.setItem("zip", e.target.value)
    })
  }

  handleInterestChange = e => {
     this.setState({ [e.target.name]: parseInt(e.target.value) }, () =>{
      localStorage.setItem("interest", parseInt(e.target.value) )
     })
  }

  handleSubmit = e => {
    e.preventDefault()

    localStorage.clear()
    localStorage.setItem("date created", dateCreated)
    localStorage.setItem("interest", this.state.interest)
    localStorage.setItem("country", this.state.country)
    localStorage.setItem("zip", this.state.zip)

    this.setState({ noZip: false })

    this.getRegions(this.state.country, this.state.zip)
      .then(regionsFromZips => {
      console.log("🚀 ~ file: regions.js:109 ~ RegionsContainer ~ regionsFromZips:", regionsFromZips)
      console.log("🚀 ~ file: regions.js:109 ~ RegionsContainer ~ state:", this.state)
      console.log("🚀 ~ file: regions.js:109 ~ RegionsContainer ~ props:",this.props)

      localStorage.setItem(
        "artRegionShortCode", regionsFromZips.regions[0].code
      )
      localStorage.setItem(
        "writingRegionShortCode", regionsFromZips.regions[1].code
      )



      this.setState({ regions: regionsFromZips.regions })

        if (localStorage.getItem("interest") === "0") {

          this.getSingleRegion(localStorage.getItem("artRegionShortCode"))
            .then(regionObject => {
              localStorage.setItem(
                "selectedShortCode", regionObject.code
              )
                navigate( `/regions/${regionObject.code}`)
          })
          } else if (localStorage.getItem("interest") === "1") { 
            this.getSingleRegion(localStorage.getItem("writingRegionShortCode"))
            .then(regionObject => {
              localStorage.setItem(
                "selectedShortCode", regionObject.code
              )
  
             navigate( `/regions/${regionObject.code}`)
            })
          }

      
    })
  }

  findRegion = e => {
    navigate("/regions")
  }

  switchRegion = () => {

    let type

    if (
      this.state.regions.length === 0 &&
      !!localStorage.getItem("artRegionShortCode")
    ) {
   
      parseInt(localStorage.getItem("interest")) === 0 ? (type = 1) : (type = 0)
      this.getBothRegions(localStorage.getItem("zip"), type)
    } else {
      console.log('bork botk')
      this.state.interest === 0 ? (type = 1) : (type = 0)

      this.setState(
        { interest: type },
        () => {
          localStorage.setItem("interest", this.state.interest)
          localStorage.setItem(
            "selectedShortCode",
            this.state.regions[this.state.interest].code
          )
          navigate(
            `/regions/${
              this.state.regions[this.state.interest].code
            }`
          )
        }

      )
    }
  }

  // resetRegion = e => {
  //   e.preventDefault()

  //   localStorage.clear()
  //   localStorage.setItem("date created", dateCreated)
  //   localStorage.setItem("interest", this.state.interest)

  //   this.setState({ noZip: false })

  //   this.queueAsyncFns(
  //     workType.map((val, index) => () =>
  //       this.getRegions(this.state.zip, index)
  //     )
  //   ).then(codes => {
  //     console.log("codes", codes)
  //     if (
  //       codes[0].regionShortCode === "ARP001" ||
  //       codes[1].regionShortCode === "WRP001" 
  //     ) {
  //       this.setState({ noZip: true })
  //     } else {
  //       localStorage.setItem("zip", this.state.zip)
  //       localStorage.setItem(
  //         "regionShortCode",
  //         codes[this.state.interest].regionShortCode
  //       )
  //       this.queueAsyncFns(
  //         codes.map(code => () => this.getSingleRegion(code.regionShortCode))
  //       ).then(bothRegions => {
      
  //         !bothRegions[this.state.interest].regionShortCode
  //           ? this.setState({ noZip: true })
  //           : this.setState({ regions: bothRegions }, () => {
     
  //               navigate(
  //                 `/regions/${bothRegions[this.state.interest].regionShortCode}`
  //               )
  //             })
  //       })
  //     }
  //   })
  // }

  render() {

    return (
      <Layout toggleSection={this.toggleSection}>
       {this.state.regions.length > 0 ? <Seo
          title={this.state.regions[this.state.interest].name}
          description={`${this.state.regions[this.state.interest].name} is managed by ${this.state.regions[this.state.interest].partnerName}`}
        /> :
        <Seo
          title="My Region"
          description="Find your Region or view all Regions"
        />}
        <Router>
          <RegionsAll
            path="/regions"
            handleCountryChange={this.handleCountryChange}
            handleZipChange={this.handleZipChange}
            handleInterestChange={this.handleInterestChange}
            handleSubmit={this.handleSubmit}
            noZip={this.state.noZip}
            interest={this.state.interest}
          />
          <RegionSingle
            path="/regions/:id/*"
            regions={this.state.regions}
            interest={this.state.interest}
            zip={this.state.zip}
            findRegion={this.findRegion}
            switchRegion={this.switchRegion}
            navSection={this.state.navSection}
          />
        </Router>
      </Layout>
    )
  }
}

export default RegionsContainer